@import '../../colors';

.letter {
    will-change: left, top, color;

    position: absolute;
    font-family: monospace;
    font-weight: bold;
    font-size: max(8vw, 8vh);
    color: $white;

    text-shadow: 0 0 1em $white;

    transition-property: all;
    transition-duration: 750ms;
    transition-timing-function: ease-in-out;
}

@import './colors';

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
}

body {
    line-height: 1;
    -webkit-font-smoothing: antialiased;

    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    background: linear-gradient($eigen-grau, $dark-blue);
}
